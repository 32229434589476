import React, { useCallback, useEffect, useState } from "react";

import { OrderStatus } from "../../../../../../../constants/defaults";

import Modal from "../../../../../../../components/common/v2/modal";
import api from "../../../../../../../api";
import { toast } from "react-toastify";

const ModalGetBuffered = ({ data, userScope }) => {
  const { order } = data || {};

  const [stocks, setStocks] = useState(null);
  const [allocationInfo, setValues] = useState(null);

  const [stockQuantity, setStockQuantity] = useState(() => {
    return parseInt(order.Offer.OfferQuantity - order.CompletedQuantity);
  });

  const onSubmit = useCallback(async () => {
    const results = await api.orders.GetStocksByOrderNumber(
      order.OrderNumber.replaceAll(" ", "")
    );

    let remainingQuantity = order.Quantity - order.CompletedQuantity;
    let availableQuantity = 0;

    if (remainingQuantity <= 0) {
      toast.info("The keys are buffered!");
      return;
    }

    if (results) {
      results.forEach((element) => {
        availableQuantity += element.Available;
      });

      console.log(availableQuantity);
    }

    console.log("availableQuantity: " + availableQuantity);
    console.log("remainingQuantity: " + remainingQuantity);
    if (availableQuantity > 0 && remainingQuantity > 0) {
      await api.orders.getBuffered(order.Id, order.Quantity);
    } else {
      toast.info("There's already a buffer waiting.");
    }
  }, [order]);

  useEffect(() => {
    const clearState = () => {
      setValues(null);
      setStocks(null);
    };

    if (!order) return clearState;

    const fetchData = async () => {
      try {
        const result = await api.orders.allocateInfo(order.Product.ProductSku);
        setValues(result);
      } catch (error) {
        toast.error(
          <div>
            {error?.response?.data.message || error.message || "Unknown error"}
          </div>
        );
      }
    };
    fetchData();

    const fetchStockData = async () => {
      try {
        const result = await api.orders.GetStocksByOrderNumber(
          order.OrderNumber.replaceAll(" ", "")
        );
        setStocks(result);
      } catch (error) {
        toast.error(
          <div>
            {error?.response?.data.message || error.message || "Unknown error"}
          </div>
        );
      }
    };
    fetchStockData();

    return clearState;
  }, [order]);

  const onChange = (value) => {
    setStockQuantity(parseInt(value));
  };

  if (!order) {
    return <span>Order data is not provided.</span>;
  }

  if (order.IsBilled || order.CompletedQuantity == order.Quantity) {
    return null;
  }

  if (
    ![
      OrderStatus.Processing,
      OrderStatus.Paused,
      OrderStatus.Closed,
      OrderStatus.OutOfStock,
      OrderStatus.PromotionExpired,
    ].includes(order.Status)
  ) {
    return null;
  }

  return (
    <Modal
      accessibility={{
        submit: {
          onClick: onSubmit,
          successMessage: "Get the buffered keys.",
          title: "Get",
        },
      }}
      trigger={{ theme: "warning", title: "Get Keys From Allocation" }}
      title="You can complete the order from the buffer if you wish."
    >
      <div className="d-flex flex-column gap-2">
        <div className="d-flex">
          <span className="w-50">
            <b>Order Number:</b>
          </span>
          <span className="w-50">{order.OrderNumber}</span>

          <span className="w-50">
            <b>Status:</b>
          </span>
          <span className="w-50">{order.Status}</span>
        </div>

        <div className="d-flex">
          <span className="w-50">
            <b>Quantity:</b>
          </span>
          <span className="w-50">{order.Quantity}</span>
        </div>
        <div className="d-flex">
          <span className="w-50">
            <b>Completed Quantity:</b>
          </span>
          <span className="w-50">{order.CompletedQuantity}</span>
        </div>
        <div className="d-flex">
          <span className="w-50">
            <b>Delivered Quantity:</b>
          </span>
          <span className="w-50">{order.DeliveredQuantity}</span>
        </div>

        {allocationInfo ? (
          <div className="d-flex">
            <span className="w-100">
              <b>Allocation Info</b>
              <code>
                <pre>{JSON.stringify(allocationInfo ?? {}, null, 4)}</pre>
              </code>
            </span>
          </div>
        ) : null}

        <div className="d-flex">
          <span className="w-100">
            <b>History Allocation Request</b>
            <code>
              <pre>
                {stocks ? (
                  JSON.stringify(stocks ?? {}, null, 4)
                ) : (
                  <div>Stock data not found</div>
                )}
              </pre>
            </code>
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default ModalGetBuffered;
